import {
    AccessManager,
    AddCampus,
    CampusManagement,
    EditCampus,
    EditOrganizationStaff,
    InviteOrganizationStaff,
} from '../../views';

export const campusRoutes = [
    {
        path: '/campus-management',
        element: <CampusManagement />,
    },
    {
        path: '/campus-management/add-campus',
        element: <AddCampus />,
    },
    {
        path: '/campus-management/edit-campus/:id',
        element: <EditCampus />,
    },
    {
        path: '/campus-management/access',
        element: <AccessManager />,
    },
    {
        path: '/campus-management/access/invite-staff',
        element: <InviteOrganizationStaff />,
    },
    {
        path: '/campus-management/access/edit-staff',
        element: <EditOrganizationStaff />,
    },
];
