import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Download } from '../../../../assets/icons/download.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as Print } from '../../../../assets/icons/print.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getStudentReceipt } from '../../../../redux/bursary/actions';
import { capitalizeFirstLetter } from '../../../../utils';

const MainContainer = tw.div`
    bg-white
    h-auto
    p-[4.8rem]
    mt-[2.9rem]
`;

const StudentReceipt = () => {
    const dispatch = useDispatch();
    const {
        state: { id, secondDegree },
    } = useLocation();
    const { studentReceipt, isLoading } = useSelector((store) => store.bursary);
    const { organizationProfile } = useSelector((store) => store.ict);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        dispatch(getStudentReceipt(id));
    }, [dispatch, id]);

    if (isLoading) return <Loader />;

    return (
        <>
            <FlexRowSpaceBetween className="pb-[3.3rem]">
                <GoBack
                    title="Students"
                    subTitle={`${studentReceipt?.name} / ${capitalizeFirstLetter(studentReceipt?.feeName)} Invoice`}
                />

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Actions
                        <Dropdown />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        close={() => setDropdownOpen(false)}
                        items={[
                            {
                                icon: <Print />,
                                name: 'Print',
                                // click: () => {
                                //     setDropdownOpen(false);
                                // },
                            },
                            {
                                icon: <Download />,
                                name: 'Download Sheet',
                                click: () => {
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>
            <FlexCentredCol>
                <PageTitle align="left">{studentReceipt?.feeName} Receipt</PageTitle>
                <Text align="left" lineHeight="1.7rem" size="1.2rem" weight="400">
                    Receipt Number: {studentReceipt?._id}
                </Text>
            </FlexCentredCol>
            <MainContainer>
                <div className="w-[10.8rem] h-[8.1rem]">
                    <img src={studentReceipt?.logo?.url} alt="school-logo" className="" />
                </div>
                <FlexCentredCol className="items-end">
                    <Text align="right">{studentReceipt?.institutionName}</Text>
                    <Text align="right">{studentReceipt?.institutionAddress}</Text>
                    <Text align="right">{organizationProfile?.info?.state}</Text>
                    <Text align="right">{organizationProfile?.institutionCountry}</Text>
                </FlexCentredCol>
                <FlexCentredCol className="items-start">
                    <Text align="left" color="#000000" weight="600">
                        Bill to
                    </Text>
                    <Text align="left">{studentReceipt?.name}</Text>
                    <Text align="left">{secondDegree}</Text>
                    <Text align="left">{studentReceipt?.getAddress}</Text>
                    {/* <Text>Nigeria</Text> */}
                </FlexCentredCol>
                <FlexCentredCol className="items-start gap-[0.8rem] pt-[2rem]">
                    <Text align="left" color="#000000" weight="600">
                        Details
                    </Text>
                    <Text align="left">Receipt number............{studentReceipt?._id}</Text>
                    <Text align="left">
                        Receipt Date................{new Date(studentReceipt?.createdAt).toLocaleDateString()}
                    </Text>
                    <Text align="left">Billing ID...........{studentReceipt?.paymentId}</Text>
                    <Text align="left">Payment for.......{capitalizeFirstLetter(studentReceipt?.feeName)}</Text>
                </FlexCentredCol>
                <FlexCentredCol className="max-w-[40.9rem] ml-[auto] gap-[1.8rem]">
                    <Text color="#000000" top="5.2rem" align="left" weight="600" className="border-b-[1px] pb-[1.1rem]">
                        {capitalizeFirstLetter(studentReceipt?.feeName)}
                    </Text>
                    <div className="flex md:flex-row flex-col gap-[4.9rem] items-center">
                        <Text align="left">Total Amount Paid in NGN</Text>

                        <Text align="left" lineHeight="3.4rem" size="2.8rem" weight="600">
                            {studentReceipt?.amount} NGN
                        </Text>
                    </div>

                    <Text align="left" weight="600" lineHeight="2.4rem">
                        Summary for {studentReceipt?.getSession} - {studentReceipt?.span}
                    </Text>
                </FlexCentredCol>
            </MainContainer>
        </>
    );
};

export default StudentReceipt;
