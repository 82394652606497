import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit3.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import AddNewDepartmentForm from '../../../components/forms/add-new-department';
import EditDepartmentForm from '../../../components/forms/edit-department';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteSecondDegree } from '../../../redux/academic-structure/actions';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { getCollegeSecondLevel } from '../../../redux/college/actions';
import { SAVE_CURRENT_DEPARTMENT } from '../../../redux/departments/slice';
import { getFacultySecondLevel } from '../../../redux/faculty/actions';



const FacultyDepartments = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentStructure } = useSelector((state) => state.academicStructure);
    const { isLoading, departments, currentFaculty } = useSelector((state) => state.faculty);
    const { currentDepartment } = useSelector((state) => state.department);
    const { isLoading: collLoading } = useSelector((state) => state.college);
    const { isLoading: deptLoading } = useSelector((store) => store.department);
    const {
        _id: facultyId,
        splitAcademicStructureTwo,
        splitAcademicStructureThree,
        splitAcademicStructureOne,
    } = currentFaculty;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deptName, setDeptName] = useState('');
    const [refetch, setRefetch] = useState(false);

    const fields = [
        {
            header: 'Name',
            accessorKey: 'departmentName',
            cell: (props) => (
                <span
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() => {
                        if (splitAcademicStructureThree) {
                            return navigate(`/faculties/department/programmes`);
                        }
                        navigate(`/faculties/department/courses`);
                    }}
                >
                    {props.getValue()}
                </span>
            ),
        },
        {
            header: 'Personnel',
            accessorKey: 'numberOfStaffs',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Courses',
            accessorKey: 'numberOfCourses',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Students',
            accessorKey: 'numberOfStudents',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
    ];

    const onDepartmentsUpdate = () => {
        setSelectedItem(null);
        setRefetch((prev) => !prev);
        setDeptName('');
    };

    const handleDelete = async () => {
        if (deptName !== currentDepartment.departmentName) {
            toast.error('Name is wrong');
        } else {
            const res = await dispatch(
                deleteSecondDegree(currentDepartment?.academicStructureId, currentDepartment?._id),
            );
            if (res) {
                setShowDelete(false);
                onDepartmentsUpdate();
            }
        }
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchParam = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (currentFaculty) {
            const currStructName = currentStructure?.name?.toLowerCase() || '';
            const { academicStructure, academicStructureId, _id: firstDegreeId } = currentFaculty || {};

            const splitStruct = academicStructure?.split('-');
            const structTwo = splitStruct?.[1];
            const params = { academicStructureId, firstDegreeId, structTwo };

            if (currStructName === 'college') {
                dispatch(getCollegeSecondLevel(params, query));
            }
            if (currStructName === 'faculty') {
                dispatch(getFacultySecondLevel(params));
            }
        }
    }, [dispatch, currentStructure, currentFaculty, query, refetch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                backLink="/academics/faculties"
                backTitle={`${splitAcademicStructureOne}`}
                backSubtitle={currentFaculty?.facultyName}
                pageTitle={currentFaculty?.facultyName}
                loading={currentStructure?.name?.toLowerCase() === 'faculty' ? isLoading : collLoading}
                fields={fields}
                data={departments}
                secondaryButtonText={`Add New ${splitAcademicStructureTwo}`}
                onSecondaryButtonClick={() => setShowForm(true)}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                actionItems={[
                    {
                        icon: <Edit />,
                        name: `Edit ${splitAcademicStructureTwo}`,
                        click: () => {
                            setShowEdit(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedItem,
                    },
                    {
                        icon: <Delete />,
                        name: `Delete ${splitAcademicStructureTwo}`,
                        click: () => {
                            setShowDelete(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedItem,
                    },
                ]}
                actionOpen={dropdownOpen}
                actionClose={() => setDropdownOpen(false)}
                onActionBtnClick={() => {
                    setDropdownOpen(!dropdownOpen);
                }}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title={`No ${splitAcademicStructureTwo}`}
                            subtitle={`Create new ${splitAcademicStructureTwo}(s)`}
                        >
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#10B981" color="#fff" onClick={() => setShowForm(true)}>
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                onRowClick={(item) => {
                    const selectedItem = departments?.find((department) => department?._id === item?._id);
                    dispatch(SAVE_CURRENT_DEPARTMENT(selectedItem));
                }}
            />
            {showForm && (
                <AddNewDepartmentForm
                    show={showForm}
                    facultyId={facultyId}
                    onFinish={onDepartmentsUpdate}
                    setShow={setShowForm}
                />
            )}
            {showEdit && <EditDepartmentForm show={showEdit} setShow={setShowEdit} onFinish={onDepartmentsUpdate} />}
            {showDelete && (
                <ConfirmActionDialogue
                    show={showDelete}
                    close={() => setShowDelete(false)}
                    title={`Delete ${splitAcademicStructureTwo}?`}
                    subtitle={`${currentDepartment?.departmentName} will be deleted.`}
                    // subtitle={`${currentFaculty?.facultyName} will be deleted.`}
                    borderLine
                    bodyText={`To confirm, enter the name of the ${splitAcademicStructureTwo} in the text field.`}
                    input
                    placeholder="Input Name"
                    value={deptName}
                    onChange={(e) => setDeptName(e.target.value)}
                    btn2Text="Yes, Delete"
                    confirmAction={handleDelete}
                    isLoading={deptLoading}
                />
            )}
        </>
    );
};

export default FacultyDepartments;
