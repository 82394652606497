import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circlePlus.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { configurePaymentGateway, modifyPaymentGateway } from '../../../redux/bursary/actions';
import { LoadingButton } from '../../buttons';
import ToggleSwitch from '../../inputs/switch';
import FormikTextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { validationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const PaymentGatewayForm = ({ show, onClose, gatewayName, refetch, gateway, setGateway, gatewayId }) => {
    const dispatch = useDispatch();

    const [active, setActive] = useState(false);

    useEffect(() => {
        if (gateway !== null) {
            setActive(gateway?.active);
        } else {
            setActive(false);
        }
    }, [gateway]);

    return (
        <div>
            <FormContainer
                close={onClose}
                show={show}
                headerIcon={<PlusIcon fill="#6366F1" />}
                title={`Configure ${gatewayName}`}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        provider: gatewayName?.toLowerCase() || '',
                        businessName: gateway?.businessName || '',
                        publicKey: gateway?.publicKey || '',
                        secretKey: gateway?.secretKey || '',
                        encryptKey: gateway?.encryptKey || '',
                        contractCode: gateway?.contractCode || '',
                        active: gateway?.active || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        values.active = active;

                        const newValueObj = Object.entries(values).reduce((acc, [key, value]) => {
                            if (value !== undefined && value !== '' && key !== 'provider') {
                                acc[key] = value;
                            }
                            return acc;
                        }, {});

                        if (gateway === null || !gatewayId) {
                            const payload = {
                                provider: values?.provider,
                                keys: [
                                    {
                                        ...newValueObj,
                                    },
                                ],
                            };

                            const res = await dispatch(configurePaymentGateway(payload));
                            if (res) {
                                refetch();
                                actions.resetForm();
                                setGateway(null);
                                onClose();
                            }
                        } else {
                            const payload = {
                                provider: values?.provider,
                                updatedKeyDetails: {
                                    ...newValueObj,
                                },
                            };

                            const res = await dispatch(modifyPaymentGateway(payload, gatewayId));
                            if (res) {
                                refetch();
                                actions.resetForm();
                                setGateway(null);
                                onClose();
                            }
                        }
                    }}
                >
                    {({ values, errors, handleChange, setFieldValue, isSubmitting }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <FormikTextInput
                                    label="Business Name"
                                    name="businessName"
                                    type="text"
                                    placeholder="Input Business Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.businessName}
                                />
                                <FormikTextInput
                                    label="Live Public Key"
                                    name="publicKey"
                                    type="password"
                                    placeholder="Input Public Key"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.publicKey}
                                />
                                <FormikTextInput
                                    label="Live Secret Key"
                                    name="secretKey"
                                    type="password"
                                    placeholder="Input Secret Key"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.secretKey}
                                />
                                {gatewayName !== 'Paystack' && gatewayName !== 'Remita' && (
                                    <FormikTextInput
                                        label={`Live ${
                                            gatewayName === 'Monnify'
                                                ? 'Contract Code'
                                                : gatewayName === 'Flutterwave' && 'Encrypt Key'
                                        }`}
                                        name={gatewayName?.toLowerCase() === 'monnify' ? 'contractCode' : 'encryptKey'}
                                        type="password"
                                        placeholder={`Input ${
                                            gatewayName === 'Monnify'
                                                ? 'Contract Code'
                                                : gatewayName === 'Flutterwave' && 'Encrypt Key'
                                        }`}
                                        errors={errors}
                                        onChange={(e) => {
                                            if (gatewayName === 'Monnify') {
                                                setFieldValue('contractCode', e.target.value);
                                            } else {
                                                setFieldValue('encryptKey', e.target.value);
                                            }
                                        }}
                                        value={gatewayName === 'Monnify' ? values?.contractCode : values?.encryptKey}
                                    />
                                )}
                                <FlexRowSpaceBetween>
                                    <Text>Enable Gateway</Text>

                                    <ToggleSwitch name="active" checked={active} setChecked={setActive} />
                                </FlexRowSpaceBetween>
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isSubmitting} onClick={() => console.log(errors)} type="submit">
                                    Configure
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </div>
    );
};

export default PaymentGatewayForm;
