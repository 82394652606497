import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
import { PageTitle } from '../../../containers/MesssageContainers';
import {
    getCommsById,
    getReviewers,
    getReviewsComments,
    reviewCommunications,
} from '../../../redux/communications/actions';
import { ActivityFeed, Overview, Preview, Reviewers } from '../components';

const ViewCommunications = () => {
    const { isLoading } = useSelector((store) => store.communications);
    const { userData, staffData } = useSelector((store) => store.user);
    const { id } = useParams();
    const dispatch = useDispatch();
    const { state: type } = useLocation();

    const [comms, setComms] = useState(null);
    const [comment, setComment] = useState('');
    const [commsReviewers, setCommsReviewers] = useState([]);
    const [commsActivities, setCommsActivities] = useState([]);
    const userId = userData?.id ? userData?.id : staffData?._id;

    const [confirmActions, setConfirmActions] = useState({
        showLeaveComment: false,
        showApprove: false,
        showEdit: false,
    });

    const handleShowComment = () => {
        setConfirmActions((prev) => ({ ...prev, showLeaveComment: true }));
    };

    const handleShowApprove = () => {
        setConfirmActions((prev) => ({ ...prev, showApprove: true }));
    };

    const handleShowEdit = () => {
        setConfirmActions((prev) => ({ ...prev, showApprove: false, showEdit: true }));
    };

    useEffect(() => {
        const getCommunications = async () => {
            const res = await dispatch(getCommsById(id));
            setComms(res);
        };
        getCommunications();
    }, [dispatch, id]);

    useEffect(() => {
        const commsReviewers = async () => {
            const res = await dispatch(getReviewers(id, type));
            setCommsReviewers(res);
        };
        commsReviewers();
    }, [dispatch, id, type]);

    useEffect(() => {
        const getReviews = async () => {
            const res = await dispatch(getReviewsComments(id, type));
            setCommsActivities(res);
        };
        getReviews();
    }, [id, type, dispatch]);

    const handleSendComment = async () => {
        const payload = { type: type, comments: comment, status: 'Pending' };
        const res = await dispatch(reviewCommunications(id, payload));
        if (res) {
            setConfirmActions(() => ({ showApprove: false, showEdit: false, showLeaveComment: false }));
        }
    };

    const handleApproveCommunication = async () => {
        const payload = { type: type, comments: comment, status: 'Approved' };
        const res = await dispatch(reviewCommunications(id, payload));
        if (res) {
            setConfirmActions(() => ({ showApprove: false, showEdit: false, showLeaveComment: false }));
        }
    };

    if (isLoading && !comms) return <Loader />;

    return (
        <>
            <GoBack
                title={`Communications / ${comms?.status === 'draft' ? 'Compose' : 'Received'} /`}
                subTitle={comms?.title}
            />
            <PageTitle top="2rem" align="left">
                {comms?.status === 'draft' ? 'Compose' : comms?.title}
            </PageTitle>
            <div className="grid grid-cols-3 gap-[16px] mt-[2rem]">
                <div className="flex flex-col col-span-2">
                    <Overview values={comms} isDraft={comms?.status === 'draft' ? true : false} />

                    <Preview
                        values={comms}
                        isReviewer={comms?.restrictedPublishersOrReviewer?.includes(userId)}
                        handleSendComment={handleSendComment}
                        handleApproveCommunication={handleApproveCommunication}
                        handleShowApprove={handleShowApprove}
                        handleShowComment={handleShowComment}
                        handleShowEdit={handleShowEdit}
                        confirmActions={confirmActions}
                        setConfirmActions={setConfirmActions}
                        onCommentChange={(e) => setComment(e.target.value)}
                        isLoading={isLoading}
                        fileUrl={comms?.attachment?.[0]?.url}
                    />
                    {comms?.senderId === userId && (
                        <ActivityFeed
                            activities={commsActivities}
                            commsType={type}
                            commsCreatedDate={new Date(comms?.createdAt).toLocaleDateString()}
                            commsCreatedTime={new Date(comms?.createdAt).toLocaleTimeString()}
                            initiator={comms?.sender}
                        />
                    )}
                </div>

                <Reviewers
                    reviewerData={commsReviewers}
                    isApproved={commsReviewers?.find((reviewer) => reviewer?.communicationType === type)}
                />
            </div>
        </>
    );
};

export default ViewCommunications;
