import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Delete } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit-black.svg';
import { ReactComponent as Send } from '../../../../assets/icons/send-black.svg';
import { ReactComponent as SendGreen } from '../../../../assets/icons/send-green.svg';
import { ConfirmActionDialogue } from '../../../../components';
import RadioInput from '../../../../components/inputs/radio';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deleteFee, getFeeDetails, publishFee } from '../../../../redux/bursary/actions';
import { GET_CURRENT_FEE } from '../../../../redux/bursary/slice';
import { capitalizeEachWord, capitalizeFirstLetter, getStatusBadge } from '../../../../utils';
import currencyFormatter from '../../../../utils/formatCurrency';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props.getValue()}</p>,
        header: <RadioInput />,
        size: 5,
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Fee',
        accessorKey: 'feeName',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Total No of Students',
        accessorKey: 'totalNoOfStudents',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'No of Students(Paid)',
        accessorKey: 'paidStudents',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'No of Students(Unpaid)',
        accessorKey: 'unpaidStudents',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Amount',
        accessorKey: 'feeAmount',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Amount Expected',
        accessorKey: 'totalAmount',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Receiving Accounts',
        accessorKey: 'receivingAccount',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Status',
        accessorKey: 'status',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Created At',
        accessorKey: 'dateCreated',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Published At',
        accessorKey: 'datePublished',
    },
];

const FeeDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading } = useSelector((store) => store.bursary);

    const [dropdownOpen, setdropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedName, setSelectedName] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [showPublish, setShowPublish] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [feeDetails, setFeeDetails] = useState(null);
    const [refetchFeeTYpe, setRefetchFeeTYpe] = useState(false);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.bursarySearch = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        const getFeeType = async () => {
            const feeDetails = await dispatch(getFeeDetails(id, query));
            setFeeDetails(feeDetails);
        };

        getFeeType();
    }, [dispatch, id, query, refetchFeeTYpe]);

    const publish = async () => {
        const payload = { feeName: selectedName };
        const res = await dispatch(publishFee(payload, selectedId));
        if (res) {
            setSelectedName('');
            setSelectedId('');
            setShowPublish(false);
            setdropdownOpen(false);
            setRefetchFeeTYpe((prev) => !prev);
        }
    };

    const deleteFees = async () => {
        if (selectedName?.toLowerCase() === 'delete') {
            const res = await dispatch(deleteFee(selectedId));
            if (res) {
                setSelectedId('');
                setConfirmDelete(false);
                setdropdownOpen(false);
                setSelectedName('');
                setRefetchFeeTYpe((prev) => !prev);
            }
        } else {
            toast.error('Input the correct fee name');
        }
    };
    const handleCheckChange = (item) => {
        const feeId = item.fee._id;
        const isChecked = feeId === selectedId;
        if (isChecked) {
            setSelectedId('');
        } else {
            setSelectedItem(item);
            setSelectedId(feeId);
        }
    };

    const modifiedFeeData = feeDetails?.map((item) => {
        return {
            action: (
                <td>
                    <RadioInput
                        value={item.fee._id}
                        onChange={() => handleCheckChange(item)}
                        checked={selectedId === item.fee._id}
                    />
                </td>
            ),
            feeName: (
                <td
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`/bursary/fees/fee-details/${item.fee._id}`)}
                >
                    {capitalizeEachWord(item.fee.allFees?.[0]?.feeName)}
                </td>
            ),
            receivingAccount: <td>{capitalizeEachWord(item.fee.allFees?.[0]?.paymentConfig)}</td>,
            totalNoOfStudents: <td>{item.totalNoOfStudents}</td>,
            paidStudents: <td>{item.paidStudents}</td>,
            unpaidStudents: <td>{item.unpaidStudents}</td>,
            feeAmount: <td>{currencyFormatter.format(item?.amountToPay || 0)}</td>,
            totalAmount: currencyFormatter.format(item?.amountExpected || 0),
            status: getStatusBadge(capitalizeFirstLetter(item?.fee?.publishStatus)),
            dateCreated: item.fee.allFees?.[0]?.createdDate,
            datePublished: item.fee.allFees?.[0]?.publishedDate,
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                backTitle={`Fees`}
                backSubtitle={`${id}`}
                secondaryButtonText="Add Fee"
                onSecondaryButtonClick={() => navigate('/bursary/fees/add-fee')}
                onActionBtnClick={() => {
                    setdropdownOpen(!dropdownOpen);
                }}
                actionOpen={dropdownOpen}
                actionClose={() => setdropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Send />,
                        name: 'Publish Fee',
                        disabled: !selectedId || selectedItem?.fee?.publishStatus?.toLowerCase() === 'published',
                        click: () => setShowPublish(true),
                    },
                    {
                        icon: <Edit />,
                        name: 'Edit Fee',
                        disabled: !selectedId || selectedItem?.fee?.publishStatus?.toLowerCase() === 'published',
                        click: () => navigate(`/bursary/fees/edit-fee/${selectedId}`),
                    },
                    {
                        icon: <Delete />,
                        name: 'Delete Fee',
                        disabled: !selectedId || selectedItem?.fee?.publishStatus?.toLowerCase() === 'published',
                        click: () => setConfirmDelete(true),
                    },
                ]}
                pageTitle={id}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={modifiedFeeData || []}
                fields={fields}
                loading={isLoading}
                onRowClick={(item) => {
                    const selectedItem = feeDetails?.find((fee) => fee?.fee?._id === item?.fee?._id);
                    handleCheckChange(selectedItem);
                    dispatch(GET_CURRENT_FEE(selectedItem));
                }}
                isActionable
            />

            {showPublish && (
                <ConfirmActionDialogue
                    input
                    placeholder="Name"
                    value={selectedName}
                    onChange={(e) => setSelectedName(e.target.value)}
                    show={showPublish}
                    svgIcon={<SendGreen />}
                    title="Publish Payment"
                    close={() => setShowPublish(false)}
                    subtitle="This Payment will be published on the student’s portal."
                    bodyText="To confirm, input 'publish' in the text field below."
                    btn2Text="Yes, Publish"
                    bgColor={'#10B981'}
                    confirmAction={publish}
                    btnDisabled={selectedName?.toLowerCase() !== 'publish'}
                />
            )}
            <ConfirmActionDialogue
                show={confirmDelete}
                close={() => setConfirmDelete(false)}
                title="Delete this fee?"
                subtitle="This fee alongside its contents and history will be deleted totally."
                borderLine
                bodyText="To confirm deletion, enter the 'delete' in the text field below."
                input
                noStyle
                value={selectedName}
                onChange={(e) => setSelectedName(e.target.value)}
                btn2Text="Yes, Delete"
                bgColor={'#EF4444'}
                confirmAction={deleteFees}
                btnDisabled={selectedName?.toLowerCase() !== 'delete'}
            />
        </>
    );
};

export default FeeDetails;
