import React from 'react';

import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../../assets/icons/people.svg';
import { CancelButton, SubmitButton } from '../../../../components/forms/sharedStyles';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    w-[1000px]
    overflow-y-auto
    mt-[3.2rem]

`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    grid-rows-4
    gap-x-6
    gap-y-5
`;

const organizationRoles = [
    { name: 'Organization Owner' },
    { name: 'Organization Member' },
    { name: 'Organization View-Only' },
];

const organizationPermissions = [
    {
        title: 'Organisation Access Manager',
        description: 'Provides administrative access to the organisation, including the ability to manage staff.',
    },
    {
        title: 'Organisation Administrator',
        description:
            'Provides administrative access to the organisation, including the ability to manage organisational functions, staff, and full access to all organization-level features.',
    },
    {
        title: 'Organisation Member',
        description:
            'Best suited for staff who do not require organisation-level access. Provides access only to the campus(es) the staff belongs to, without any organisation-level access.',
    },
    {
        title: 'Organisation View-Only',
        description: 'Provides view-only access to everything in the organisation, including all campuses within it',
    },
];

const EditOrganizationStaff = () => {
    const navigate = useNavigate();

    return (
        <div className="flex gap-[5rem]">
            <Container>
                <Formik
                    initialValues={{}}
                    enableReinitialize
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        console.log('values');
                    }}
                >
                    {({ errors, handleChange, values, touched, setFieldValue }) => (
                        <Form>
                            <>
                                <FlexCentredRow className="gap-2 mb-8">
                                    <PaperIcon />
                                    <Text weight="600" size="1.6rem">
                                        Add Staff
                                    </Text>
                                </FlexCentredRow>
                                <GridRows>
                                    <TextInput
                                        name="surname"
                                        type="text"
                                        label="Surname"
                                        placeholder="Surname"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.surname}
                                    />
                                    <TextInput
                                        name="firstName"
                                        type="text"
                                        label="First Name"
                                        placeholder="First Name"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.firstName}
                                    />
                                    <TextInput
                                        name="otherName"
                                        label="Other Names"
                                        type="text"
                                        placeholder="Other Names"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.otherName}
                                    />
                                    <TextInput
                                        name="staffEmail"
                                        type="text"
                                        label="Official Email Address"
                                        placeholder="Email Address"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.staffEmail}
                                    />
                                    <SelectInput
                                        name="role"
                                        objProp="name"
                                        label="Organization Role"
                                        placeholder="Select role"
                                        data={organizationRoles || []}
                                        useComponentState={false}
                                        onChange={(selected) => {
                                            setFieldValue('role', selected[0].name);
                                        }}
                                        error={errors.role}
                                    />
                                </GridRows>
                                <FlexRowEnd className="gap-3 mt-4">
                                    <CancelButton type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </CancelButton>
                                    <SubmitButton type="submit">Invite</SubmitButton>
                                </FlexRowEnd>
                            </>
                        </Form>
                    )}
                </Formik>
            </Container>
            <div className="w-[477px] bg-white p-[3.2rem] mt-[3.2rem]">
                <Text weight="600" size="1.6rem" align="left">
                    Organisational Permissions
                </Text>
                <div className="mt-[3rem] flex flex-col gap-[2.4rem]">
                    {organizationPermissions?.map((item, i) => (
                        <div className="" key={i}>
                            <Text weight="600" size="1.6rem" align="left">
                                {item.title}
                            </Text>{' '}
                            <Text weight="400" size="1.6rem" align="left">
                                {' '}
                                - {item?.description}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EditOrganizationStaff;
