import axios from 'axios';

import { parseJSON } from '../utils/useJSON';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
};

// update axiosInstance
const axiosInstance = axios.create(defaultOptions);

//set the auth token if there's any for any request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = parseJSON('token', '');
        const originUnit = parseJSON('originUnit', '');
        const campusId = sessionStorage.getItem('campusId');
        const unitId = sessionStorage.getItem('unitId');

        if (config && config.headers) {
            config.url = config.url.replace(':campusId', campusId);
            config.url = config.url.replace(':unitId', unitId);

            config.headers.Authorization = `Bearer ${token}`;
            config.headers.originunit = originUnit;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // const originalRequest = error.config;
        // if (error.response && error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     return axios(originalRequest);
        // }
        if (error.response && error.response.status === 401) {
            localStorage.clear();

            if (window.location.pathname !== '/sign-in-staff') {
                window.location.replace('/sign-in-staff');
            }
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
