import React, { useEffect, useState } from 'react';

import { SelectInput } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { Select } from '../../../components';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { editAgent, getAllBanksFromPaystack } from '../../../redux/bursary/actions';

import { AgentValidationSchema } from './add-agent';

// import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    max-w-[1000px]
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
`;

const EditAgent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { agent } = state || {};

    const [allbanks, setAllBanks] = useState([]);

    useEffect(() => {
        const getBanks = async () => {
            const res = await dispatch(getAllBanksFromPaystack());
            setAllBanks(res);
        };
        getBanks();
    }, [dispatch]);

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Edit Agent
                </PageTitle>
            </FlexCentredRow>

            <Formik
                initialValues={
                    agent || {
                        session: '',
                        companyName: '',
                        companyAddress: '',
                        companyEmail: '',
                        companyPhoneNumber: '',
                        companyRegistrationNumber: '',
                        agentAccountName: '',
                        agentAccountNumber: '',
                        agentAccountType: '',
                        agentBankName: '',
                    }
                }
                validationSchema={AgentValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(editAgent(values, agent?._id));
                    if (res) navigate(-1);
                }}
            >
                {({ handleChange, errors, values, setFieldValue }) => (
                    <Form>
                        <div className="max-w-[900px]">
                            <Container>
                                <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                    <EditIcon fill="#6366f1" />
                                    <Text size="1.6rem" weight="600">
                                        Edit Agent
                                    </Text>
                                </FlexCentredRow>
                                <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid">
                                    <FlexCentredCol>
                                        <TextInput
                                            name="companyName"
                                            onChange={handleChange}
                                            label="Company Name"
                                            type="text"
                                            placeholder="Input Company Name"
                                            errors={errors}
                                            value={values?.companyName}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="companyAddress"
                                            onChange={handleChange}
                                            label="Company Address (Optional)"
                                            type="text"
                                            placeholder="Input Address"
                                            errors={errors}
                                            value={values?.companyAddress}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="companyEmail"
                                            onChange={handleChange}
                                            label="Company Email"
                                            type="text"
                                            placeholder="Input Company Email"
                                            errors={errors}
                                            value={values?.companyEmail}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="companyPhoneNumber"
                                            onChange={handleChange}
                                            label="Company Phone Number (Optional)"
                                            type="text"
                                            placeholder="Input Phone Number"
                                            errors={errors}
                                            value={values?.companyPhoneNumber}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="companyRegistrationNumber"
                                            onChange={handleChange}
                                            label="Company Registration Number (Optional)"
                                            type="text"
                                            placeholder="Input Company Registration Number"
                                            errors={errors}
                                            value={values?.companyRegistrationNumber}
                                        />
                                    </FlexCentredCol>
                                </div>
                                <div className="mt-[2rem]">
                                    <Text align="left" color="#1F2937" weight="600" size="1.6rem" bottom="1.6rem">
                                        Account Information
                                    </Text>
                                </div>
                                <hr />
                                <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid mt-[2.5rem]">
                                    <FlexCentredCol>
                                        <TextInput
                                            name="agentAccountName"
                                            onChange={handleChange}
                                            label="Account Name"
                                            type="text"
                                            placeholder="Input Account Name"
                                            errors={errors}
                                            value={values?.agentAccountName}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="agentAccountNumber"
                                            onChange={handleChange}
                                            label="Account Number"
                                            type="text"
                                            placeholder="Input Account Number"
                                            errors={errors}
                                            value={values?.agentAccountNumber}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <Select
                                            name="agentBankName"
                                            objProp="name"
                                            data={allbanks || []}
                                            placeholder="Select Bank Name"
                                            searchable
                                            label="Bank"
                                            onChange={(selected) => {
                                                setFieldValue('agentBankName', selected?.[0]?.name);
                                            }}
                                            height="max-h-[20rem]"
                                            passedSelectedItems={values?.agentBankName}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <SelectInput
                                            name="agentAccountType"
                                            objProp="name"
                                            data={[{ name: 'Savings Account' }, { name: 'Current Account' }]}
                                            placeholder="Select Account Type"
                                            label="Account Type"
                                            onChange={(selected) => {
                                                setFieldValue('agentAccountType', selected?.[0]?.name);
                                            }}
                                            passedSelectedItems={values?.agentAccountType}
                                        />
                                    </FlexCentredCol>
                                </div>
                            </Container>
                            <div className="flex items-center gap-[1rem] my-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton bgColor="#6366F1" color="#fff" type="submit">
                                    Update
                                </LoadingButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* </Container> */}
        </>
    );
};

export default EditAgent;
