import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as Closeicon } from '../../assets/images/close.svg';
import { Text } from '../../containers/MesssageContainers';
import { useTableContext } from '../../context/tableContext';
import { LoadingButton } from '../buttons';
import { CancelButton } from '../forms/sharedStyles';
import Checkbox from '../inputs/checkbox';
import RadioInput from '../inputs/radio';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 16px;
    }
`;

const radioGroup = [
    { label: '10 Resources', name: '10_resources', id: 10 },
    { label: '25 Resources', name: '25_resources', id: 25 },
    { label: '50 Resources', name: '50_resources', id: 50 },
];

const tableSettingsOptions = [
    {
        title: 'Alternate row colours',
        description:
            'Enable to show table rows with alternate colours for better visibility, disable to show table rows with same colour',
        id: 'striped',
    },
    {
        title: 'Freeze First Column',
        description: 'Enable to fix first Column ',
        id: 0,
    },
];

const TableSettings = ({ show, close, onApplySettings }) => {
    const { tableSettings } = useTableContext();

    const [settingsToApply, setSettingsToApply] = useState({
        stripedRow: tableSettings?.stripedRow || false,
        stickyColumns: tableSettings?.stickyColumns || null,
        numOfPages: tableSettings?.numOfPages || 10,
        isWrapText: tableSettings?.isWrapText,
    });

    const onRadioChange = (value) => {
        setSettingsToApply((prev) => ({ ...prev, numOfPages: value }));
    };

    const handleStripedRows = () => {
        setSettingsToApply((prev) => ({ ...prev, stripedRow: !prev.stripedRow }));
    };

    const handleStickyColumns = (item) => {
        if (settingsToApply.stickyColumns === item?.id) {
            setSettingsToApply((prev) => ({ ...prev, stickyColumns: null }));
        } else {
            setSettingsToApply((prev) => ({ ...prev, stickyColumns: item?.id }));
        }
    };

    const handleWrapText = () => {
        setSettingsToApply((prev) => ({ ...prev, isWrapText: !prev.isWrapText }));
    };

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="w-[695px] bg-white rounded-[8px]">
                <div className="flex items-center rounded-[8px] justify-between bg-[#F3F4F6] p-[20px]">
                    <Text size="1.6rem" weight="500" color="#111928" align="left">
                        Preferences
                    </Text>
                    <Closeicon className="cursor-pointer" onClick={close} />
                </div>
                <div className="grid grid-cols-3 gap-[58px] p-[20px]">
                    <div className="flex flex-col gap-[32px]">
                        <div>
                            <Text weight="500" size="16px" align="left" bottom="1.8rem">
                                Page Size
                            </Text>
                            <div className="flex flex-col gap-[18px]">
                                {radioGroup?.map((radio) => (
                                    <RadioInput
                                        key={radio?.name}
                                        label={radio?.label}
                                        name={radio?.name}
                                        onClick={() => onRadioChange(radio?.id)}
                                        checked={settingsToApply.numOfPages === radio?.id}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="flex">
                            <Checkbox
                                checked={settingsToApply?.isWrapText}
                                className="mt-[0.5rem]"
                                onClick={handleWrapText}
                            />
                            <div className="flex flex-col gap-[10px]">
                                <Text align="left" size="1.6rem" weight="500">
                                    Wrap Text
                                </Text>
                                <Text align="left" size="1.4rem" weight="400" lineHeight="2.4rem" color="#374151">
                                    Enable to wrap table cell content, disable to truncate text.
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 flex flex-col gap-[24px]">
                        {tableSettingsOptions?.map((item, i) => (
                            <div key={item.id} className="flex items-start">
                                <Checkbox
                                    checked={
                                        i === 0
                                            ? settingsToApply?.stripedRow
                                            : i === 1
                                            ? settingsToApply?.stickyColumns === 0
                                            : false
                                    }
                                    className="mt-[0.5rem]"
                                    onClick={() => {
                                        if (item?.id === 'striped') {
                                            handleStripedRows();
                                        } else {
                                            handleStickyColumns(item);
                                        }
                                    }}
                                />
                                <div className="flex flex-col gap-[10px]">
                                    <Text align="left" size="1.6rem" weight="500">
                                        {item?.title}
                                    </Text>
                                    <Text align="left" size="1.4rem" weight="400" lineHeight="2.4rem" color="#374151">
                                        {item?.description}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex items-center gap-[16px] p-[20px]">
                    <CancelButton type="button" onClick={close} className="ml-auto">
                        Cancel
                    </CancelButton>
                    <LoadingButton type="button" onClick={() => onApplySettings(settingsToApply)}>
                        Apply Changes
                    </LoadingButton>
                </div>
            </div>
        </StyledModal>
    );
};

export default TableSettings;
