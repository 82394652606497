import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete2.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit3.svg';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send-black.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-amber.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import SendOfferList from '../../../../components/popups/send-offer-list';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import {
    disengageStaff,
    getStaffById,
    inviteStaffToAdmin,
    inviteStaffToLecturersPortal,
} from '../../../../redux/staff/actions';

import Experience from './tabs/Experience';
import NonTeachingExperience from './tabs/NonTeachingExperience';
import Overview from './tabs/Overview.';
import Personal from './tabs/Personal';
import Publication from './tabs/Publication';

const StaffDetails = () => {
    const navigate = useNavigate();
    const { id: staffId } = useParams();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((store) => store.staff);

    const [openDropDown, setOpenDropDown] = useState(false);
    const [, /*tab*/ setTab] = useState(0);
    const [disengageInputValue, setDisengageInputValue] = useState('');
    const [reinstateInputValue, setReinstateInputValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [staffDetails, setStaffdetails] = useState(null);
    const { staff, bioData, experience, publication, staffAcademyInfo } = staffDetails || {};

    const fullName = `${staff?.firstName} ${staff?.otherName || ''} ${staff?.surname}`;

    const handleSendInvite = async () => {
        const payload = { staffNames: [fullName] };

        const res =
            openModal === 'invite-admin'
                ? await dispatch(inviteStaffToAdmin(payload))
                : await dispatch(inviteStaffToLecturersPortal(payload));

        if (res) {
            toast.success('Invite sent to staff successfully!');
            setOpenModal(null);
        }
    };

    const handleDisengage = async () => {
        if (disengageInputValue.toLowerCase() !== 'disengage') return toast.error("Input doesn't match");

        const res = await dispatch(disengageStaff(staffId));
        if (res) {
            toast.success('Staff disengaged successfully!');
            setOpenModal('');
            setDisengageInputValue('');
        }
    };

    useEffect(() => {
        if (staffId) {
            const getDetails = async () => {
                const res = await dispatch(getStaffById(staffId));
                if (res) {
                    setStaffdetails(res);
                }
            };
            getDetails(staffId);
        }
    }, [dispatch, staffId]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <GoBack title="Staff Management" subtitle="Staff Details" />

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenDropDown(!openDropDown)}>
                        Actions
                        <Dropdown />
                    </Button>
                    <ActionsPopup
                        open={openDropDown}
                        close={() => setOpenDropDown(false)}
                        items={[
                            {
                                icon: <EditIcon />,
                                name: 'Edit Staff',
                                click: () => {
                                    navigate('/personnel/staff-management/edit-staff', { state: staff });
                                },
                            },
                            {
                                icon: <SendIcon />,
                                name: 'Invite to Admin Portal',
                                click: () => {
                                    setOpenModal('invite-admin');
                                    setOpenDropDown(false);
                                },
                                disabled: staff?.staffStatus === 'ACTIVE',
                            },
                            {
                                icon: <SendIcon />,
                                name: 'Invite to Lecturers Portal',
                                click: () => {
                                    setOpenModal('invite-lecturer');
                                    setOpenDropDown(false);
                                },
                                notShown: staff?.staffType !== 'Teaching',
                                disabled: staff?.lecturerStatus === 'ACTIVE',
                            },
                            {
                                icon: <DeleteIcon />,
                                name: 'Disengage Staff',
                                click: () => {
                                    setOpenModal('disengage');
                                    setOpenDropDown(false);
                                },
                            },
                            {
                                icon: <RefreshIcon />,
                                name: 'Reinstate Staff',
                                click: () => {
                                    setOpenModal('reinstate');
                                    setOpenDropDown(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Overview</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Experience</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink onClick={() => setTab(2)}>Personal</CNavLink>
                    </CNavItem>
                    {staff?.staffType === 'Teaching' ? (
                        <CNavItem>
                            <CNavLink onClick={() => setTab(3)}>Publication</CNavLink>
                        </CNavItem>
                    ) : null}
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Overview bio={bioData} staff={staff} />
                    </CTabPane>
                    {staff?.staffType === 'Teaching' ? (
                        <CTabPane>
                            <Experience exp={experience} />
                        </CTabPane>
                    ) : (
                        <CTabPane>
                            <NonTeachingExperience exp={staffAcademyInfo} />
                        </CTabPane>
                    )}
                    <CTabPane>
                        <Personal bio={bioData} staff={staff} />
                    </CTabPane>
                    {staff?.staffType === 'Teaching' && (
                        <CTabPane>
                            <Publication pub={publication} />
                        </CTabPane>
                    )}
                </CTabContent>
            </CTabs>

            <ConfirmActionDialogueWithInput
                show={openModal === 'disengage'}
                title={`Disengage ${fullName}`}
                subtitle="The staff's access will be terminated and the profile would be archived."
                subtitle2="To confirm Disengagement, enter 'disengage' in the text field."
                close={() => setOpenModal(null)}
                btn2Text="Yes, Disengage"
                confirmAction={handleDisengage}
                setInputValue={setDisengageInputValue}
                value={disengageInputValue}
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'reinstate'}
                title={`Reinstate ${fullName}`}
                subtitle="The staff’s access will be reinstated and their profiles will be restored."
                subtitle2="To confirm re-engagement, enter the name of the staff in the text field."
                close={() => setOpenModal(null)}
                btn2Text="Yes, Reinstate"
                setdisengageInputValue={setReinstateInputValue}
                value={reinstateInputValue}
                svgIcon={<WarningIcon />}
            />

            <SendOfferList
                show={openModal === 'invite-admin' || openModal === 'invite-lecturer'}
                list={[{ fullName, id: staff?._id }]}
                onSend={() => handleSendInvite()}
                title="Send Invite"
                subtitle={`The selected staff member will be invited to the ${
                    openModal === 'invite-admin' ? 'admin' : 'lecturers'
                } portal`}
                close={() => setOpenModal(null)}
                idField="id"
            />
        </div>
    );
};

export default StaffDetails;
