import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Check } from '../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as CircularPlus } from '../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import ApplyDiscount from '../../../components/popups/apply-discount';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import useSearch from '../../../hooks/useSearch';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { deleteDiscount, getAllDiscounts } from '../../../redux/bursary/actions';

const fields = [
    {
        cell: (props) => (
            <Link to={props.row.original._id} state={{ discount: props.row.original }}>
                <p className="text-[#2563EB]">{props?.getValue()}</p>
            </Link>
        ),
        header: 'Discount Name',
        accessorKey: 'discountName',
    },
    {
        cell: (props) => <p>{props?.getValue().replace(/-/g, ' ')}</p>,
        header: 'Discount Type',
        accessorKey: 'discountType',
    },
    {
        cell: (props) => <p>{props?.getValue() || ''}</p>,
        header: 'Percentage Off',
        accessorKey: 'discountPercentage',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Number of Student Applied',
        accessorKey: 'beneficiaryCount',
    },
];

const DiscountList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allDiscounts } = useSelector((state) => state.bursary);
    const { sessions, activeSession } = useSelector((state) => state.admission);

    const [session, setSession] = useState(activeSession || '');

    const [filteredDiscounts, setFilteredDiscounts] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const filteredResults = useSearch(searchValue, filteredDiscounts, ['discountName']) || [];

    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const sessionItems = sessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSession(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleDelete = async () => {
        const res = await dispatch(deleteDiscount(selectedItem?._id));
        if (res) {
            setOpenModal('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        if (session && allDiscounts?.length > 0) {
            const sessionDiscounts = allDiscounts?.filter((discount) => discount?.session === session);
            setFilteredDiscounts(sessionDiscounts);
        } else {
            setFilteredDiscounts(allDiscounts);
        }
    }, [session, allDiscounts]);

    const query = useMemo(() => {
        let queryParams = {};

        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [session]);

    useEffect(() => {
        dispatch(getAllDiscounts(query));
    }, [dispatch, toggleRefetch, query]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle="Discounts"
                secondaryButtonText="Apply Discount"
                secondaryButtonIcon={<Check />}
                disableSecondaryBtn={!selectedItem}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onSecondaryButtonClick={() => setOpenModal('apply')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <CircularPlus />,
                        name: 'Create Discount',
                        click: () => navigate('/bursary/discounts/create'),
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Edit Discount',
                        disabled: !selectedItem,
                        click: () =>
                            navigate('/bursary/discounts/edit', { state: { selectedDiscount: selectedItem || {} } }),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Discount',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                sessionPlaceHolder={session}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
                sessionItems={sessionItems}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                data={filteredResults || []}
                fields={fields}
                loading={isLoading}
                width={'100%'}
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this discount?"
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />

            <ApplyDiscount
                show={openModal === 'apply'}
                close={() => setOpenModal('')}
                discount={selectedItem}
                onFinish={() => {
                    setToggleRefetch((prev) => !prev);
                }}
            />
        </>
    );
};

export default DiscountList;
