import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
// import { encodeQuery } from '../../utils';

import { GET_ALL_CAMPUS, LOADING, STOP_LOADING } from './slice';

export const createCampus = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}campus/create_campus`, payload);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message || 'Campus Created Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const updateCampus = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}campus/edit_campus/${id}`, payload);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message || 'Campus Updated Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteCampus = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}campus/delete_campus/${id}`);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message || 'Campus Deleted Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllCampus = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}campus/get_all_campuses`);
        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_CAMPUS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
