import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import ListEmptyContent from '../../../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../../../components/shared';
import { CenteredContainer, FlexCentredRow, RelativeContainer } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../../redux/admissions/actions';
import { getInternalCourseForm } from '../../../../../redux/course-form-magt/actions';
import { generateLevels } from '../../../../../utils';
import { getFullName } from '../../../../../utils/getInitials';

const CourseFormManagement = () => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.courseForm);
    const { currentDepartment } = useSelector((state) => state.department);
    const { sessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { departmentId, studyDuration, academicStructureId, academicStructure } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const levels = generateLevels(studyDuration);

    const [session, setSession] = useState(activeSession || '');
    const [students, setStudents] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [semester, setSemester] = useState(activeSemester?.split(' ')?.[0] || 'First');
    const [selectedLevel, setSelectedLevel] = useState('100');
    const [searchValue, setSearchValue] = useState('');

    const fields = [
        {
            header: 'Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <Link
                    to={`${row.original.studentId}`}
                    state={{ session, semester, level: selectedLevel }}
                    style={{ color: '#2563EB' }}
                >
                    <p>{getFullName(row.original.lastName, row.original.firstName, row.original.middleName || '')}</p>
                </Link>
            ),
        },
        {
            header: 'Matric No.',
            accessorKey: 'matricNumber',
        },

        {
            header: 'Submission Status',
            accessorKey: 'submissionStatus.status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
        // {
        //     header: 'HOD Status',
        //     // accessorKey: 'status',
        // },
        // {
        //     header: 'Dean Status',
        //     // accessorKey: 'status',
        // },
    ];

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    const actionItemsLevel = levels.map((item) => ({
        name: `${item}`,
        click: () => {
            setSelectedLevel(item);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    useEffect(() => {
        if (session && lastDegreeId && selectedLevel && semester) {
            const getList = async () => {
                const res = await dispatch(
                    getInternalCourseForm({
                        academicStructureId,
                        lastDegreeId,
                        session,
                        semester,
                        level: selectedLevel,
                    }),
                );
                if (res) return setStudents(res);
                setStudents([]);
            };

            getList();
        }
    }, [session, lastDegreeId, dispatch, selectedLevel, academicStructureId, semester]);

    return (
        <>
            <PageLayout
                pageTitle="Course Form Management"
                showTableUtils
                isActionable
                data={students || []}
                fields={fields}
                loading={isLoading}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                tableUtilsChildren={
                    <FlexCentredRow className=" gap-[1.6rem]">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('semester')}
                            >
                                {`${semester} Semester` || 'Semester'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'semester'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSemester}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('level')}
                            >
                                {`${selectedLevel} Level` || 'Level'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'level'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemsLevel}
                            />
                        </RelativeContainer>{' '}
                    </FlexCentredRow>
                }
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No students yet"></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </>
    );
};

export default CourseFormManagement;
