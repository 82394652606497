import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings-blue.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input';
import { Loader } from '../../../../components/loader';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd } from '../../../../containers/ScreenContainers';
import {
    getMatricNumberFormats,
    getSelectedMatricNumberFormat,
    setMatricFormat,
} from '../../../../redux/settings/actions';

import { ValidationSchema } from './ValidationSchema';

const MatricNumberSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, matricSettings, matricNumberFormats = [] } = useSelector((state) => state.settings);

    const [altLoading, setAltLoading] = useState(false);

    const savedFormat = useMemo(
        () => matricNumberFormats?.find((item) => item?._id === matricSettings?.matricFormatId),
        [matricNumberFormats, matricSettings],
    );

    useEffect(() => {
        dispatch(getMatricNumberFormats());
        dispatch(getSelectedMatricNumberFormat());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subTitle="Matric Number Settings" />

            <div className="rounded-[16px] bg-white p-[32px] mt-12 max-w-[1000px]">
                <div className="flex gap-3 mb-[32px]">
                    <SettingsIcon />
                    <Text size="1.6rem" weight="600">
                        Set Matric Number Pattern
                    </Text>
                </div>

                <Formik
                    initialValues={{
                        id: savedFormat?._id || '',
                        sample: savedFormat?.sample || '',
                        description: savedFormat?.description || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        setAltLoading(true);
                        const res = await dispatch(setMatricFormat(values.id));
                        if (res) {
                            toast.success('Matric number pattern set successfully');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="grid gap-8 max-w-[80%]">
                                <Select
                                    name="id"
                                    label="Select the matric number pattern that fits perfectly to your organisation’s matric number"
                                    objProp="description"
                                    placeholder="Select"
                                    useComponentState={false}
                                    passedSelectedItems={values ? [{ description: values.description }] : null}
                                    data={matricNumberFormats}
                                    onChange={(selected) => {
                                        setFieldValue('id', selected[0]._id);
                                        setFieldValue('sample', selected[0].sample);
                                        setFieldValue('description', selected[0].description);
                                    }}
                                    error={errors.id}
                                />

                                <TextInput
                                    name="sample"
                                    type="text"
                                    label="Sample"
                                    placeholder="Sample"
                                    disabled
                                    value={values.sample}
                                    errors={errors}
                                />
                            </div>

                            <FlexRowEnd className="gap-3 mt-12">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton isLoading={altLoading} disabled={altLoading} type="submit">
                                    Apply Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default MatricNumberSettings;
