import React, { useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// if new icons are to be used, remove the fill properties within the icon svg
import { ReactComponent as StudentAffairsIcon } from '../../assets/icons/affairs.svg';
import { ReactComponent as BursaryIcon } from '../../assets/icons/bursary.svg';
import { ReactComponent as IctIcon } from '../../assets/icons/ict.svg';
import { ReactComponent as ClinicIcon } from '../../assets/icons/medicals.svg';
import { ReactComponent as AcadPlanningIcon } from '../../assets/icons/sb-academic-plan.svg';
import { ReactComponent as AdmissionsIcon } from '../../assets/icons/sb-admissions.svg';
import { ReactComponent as AuditIcon } from '../../assets/icons/sb-audit.svg';
import { ReactComponent as ExamsIcon } from '../../assets/icons/sb-exams-records.svg';
import { ReactComponent as LibraryIcon } from '../../assets/icons/sb-library.svg';
import { ReactComponent as PersonnelIcon } from '../../assets/icons/sb-personnel.svg';
import { ReactComponent as QaIcon } from '../../assets/icons/sb-qa.svg';
import { ReactComponent as RecordsIcon } from '../../assets/icons/sb-records.svg';
import { ReactComponent as SchoolsIcon } from '../../assets/icons/sb-schools.svg';
import { ReactComponent as SecurityIcon } from '../../assets/icons/sb-security.svg';
import { ReactComponent as WorksIcon } from '../../assets/icons/sb-works.svg';
import { ReactComponent as SportsIcon } from '../../assets/icons/sports.svg';
import { Card } from '../../components';
import { Text } from '../../containers/MesssageContainers';
import { getActiveSemester, getActiveSession } from '../../redux/admissions/actions';
import { getAllCampus } from '../../redux/campus-management/actions';
import { getActiveOrganisationUnits } from '../../redux/organisation-unit/actions';
import { SAVE_CURRENT_UNIT } from '../../redux/organisation-unit/slice';

const ICONS = {
    AdmissionsIcon: AdmissionsIcon,
    RecordsIcon: RecordsIcon,
    SchoolsIcon: SchoolsIcon,
    BursaryIcon: BursaryIcon,
    ExamsIcon: ExamsIcon,
    IctIcon: IctIcon,
    StudentAffairsIcon: StudentAffairsIcon,
    LibraryIcon: LibraryIcon,
    ClinicIcon: ClinicIcon,
    SportsIcon: SportsIcon,
    QaIcon: QaIcon,
    WorksIcon: WorksIcon,
    AuditIcon: AuditIcon,
    PersonnelIcon: PersonnelIcon,
    AcadPlanningIcon: AcadPlanningIcon,
    SecurityIcon: SecurityIcon,
};

const OrganisationUnits = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeOrgUnits } = useSelector((store) => store.organisationUnits);
    const { staffData } = useSelector((store) => store.user);
    const { userType = '', organizationUnit = [] } = staffData || {};

    const campusId = sessionStorage?.getItem('campusId');

    const handleClick = (unit) => {
        if (userType === 'staff' && !organizationUnit?.includes(unit?._id)) {
            return toast.error("You don't have access to this unit");
        }
        sessionStorage.setItem('unitId', unit?._id);
        sessionStorage.setItem('unitName', unit?.unitName);
        navigate(unit.to);

        // TODO Is the dispatch still needed in redux store?
        dispatch(SAVE_CURRENT_UNIT(unit));
    };

    useEffect(() => {
        dispatch(getActiveOrganisationUnits());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
        dispatch(getAllCampus());
    }, [dispatch, campusId]);

    return (
        <Card>
            <Text weight="600" size="1.9rem" lineHeight="2.8rem" align="left" bottom="3rem">
                Organisation Units
            </Text>
            <ul className="grid sm:grid-cols-2 md:grid-cols-3 gap-y-6">
                {activeOrgUnits
                    ?.filter((item) => item.to)
                    ?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName))
                    ?.map((item) => {
                        const Icon = ICONS[item.src];

                        return (
                            <li
                                key={item._id}
                                className={`flex items-center gap-x-6 cursor-pointer w-max`}
                                onClick={() => handleClick(item)}
                            >
                                <div className="bg-[#6366F1] rounded-md flex items-center justify-center h-[40px] w-[40px] p-2">
                                    <Icon fill={item.to.startsWith('/clinic') ? '#6366F1' : '#fff'} />
                                </div>
                                <Text color="#3B82F6">{item.unitName}</Text>
                            </li>
                        );
                    })}
            </ul>
        </Card>
    );
};

export default OrganisationUnits;
