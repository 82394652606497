import React from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import Avatar from 'react-avatar';

import { ReactComponent as StudentIcon } from '../../../../assets/icons/people2.svg';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn } from '../../../../containers/ScreenContainers';

const fields = [
    { accessorKey: 'feeName', header: 'Fee' },
    { accessorKey: 'level', header: 'Level' },
    { accessorKey: 'amount', header: 'Amount Paid' },
    {
        accessorKey: 'amountOutstanding',
        cell: ({ row }) => (
            <Text align="left" color="#9CA3AF">
                {row.original?.amountExpected - row.original?.amount}
            </Text>
        ),
        header: 'Amount Outstanding',
    },
];

const PaymentDetailsModal = ({ basicData, updateInfo, uploads, studentFee, session }) => {
    const StudentDetails = [
        { title: 'Full Name', value: `${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}` },
        { title: 'Matric No', value: basicData?.matricNumber },
        { title: 'Department', value: basicData?.program },
        { title: 'Sex', value: updateInfo?.gender },
        { title: 'Year of Admission', value: basicData?.admissionYear?.slice(0, 10) },
        { title: 'Mode of Entry', value: basicData?.admissionType },
        { title: 'Date of Birth', value: updateInfo?.dateOfBirth?.slice(0, 10) },
        { title: 'Faculty', value: basicData?.faculty },
        { title: 'Session', value: session?.session },
    ];

    return (
        <div className="p-[2.4rem] bg-white rounded-[8px]">
            <FlexCentredRow className="gap-[0.4rem] pb-[2.8rem]">
                <StudentIcon fill="#3B82F6" />
                <Text size="1.9rem" weight="600">
                    Student Details
                </Text>
            </FlexCentredRow>
            <FlexCentredRow className="gap-[4.8rem]">
                {uploads?.passport?.[0]?.url ? (
                    <img alt="profile" src={uploads?.passport?.[0]?.url} className="h-[18rem] w-[15rem]" />
                ) : (
                    <Avatar name={'OLA'} size="150" textSizeRatio={1.75} className="rounded-[8px]" />
                )}

                <div className="grid grid-cols-3 gap-x-[8rem] gap-y-[2.4rem]">
                    {StudentDetails.map(({ title, value }, index) => (
                        <FlexColumn className="" key={index}>
                            <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                {title}
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                {value}
                            </Text>
                        </FlexColumn>
                    ))}
                </div>
            </FlexCentredRow>
            <div className="pt-[7rem] shadow-paymentModalShadow">
                <Table data={studentFee || []} columns={fields} />
            </div>
        </div>
    );
};

export default PaymentDetailsModal;
