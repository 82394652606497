import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import PageLayout from '../../../layout/page-layout/PageLayout';

const fields = [
    {
        header: 'Staff Name',
        accessorKey: 'staffName',
    },
    {
        header: 'Staff Email',
        accessorKey: 'staffEmail',
    },
    {
        header: 'Number of Campuses',
        accessorKey: 'campuses',
    },
    {
        header: 'Organization Role',
        accessorKey: 'role',
    },
];

const AccessManager = () => {
    const navigate = useNavigate();
    const { isLoading } = useSelector((store) => store.campus);

    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    //  const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState(null);
    return (
        <div>
            <PageLayout
                pageTitle="Access Management"
                secondaryButtonText="Invite Staff"
                onSecondaryButtonClick={() => navigate('invite-staff')}
                fields={fields}
                data={[]}
                onSelectedItemsChange={(items) => setSelected(items[0])}
                actionItems={[
                    {
                        icon: <EditIcon fill="#1f2937" />,
                        name: 'Edit Staff',
                        click: () => navigate(`edit-staff/${selected?._id}`, { state: { campus: selected } }),
                        disabled: !selected,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Staff',
                        click: () => setOpenModal('delete-staff'),
                        disabled: !selected,
                    },
                ]}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                isActionable
                actionType="radio"
                loading={isLoading}
            />
        </div>
    );
};

export default AccessManager;
