import React from 'react';

import { ReactComponent as LogoutIcon } from '../../../assets/icons/exit.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';

import AccountInformation from './account-information';
import CampusLinks from './campus-links';
import ProfileLinks from './profile-links';

const SwitchCampus = ({
    open,
    close,
    organizationLogo,
    organizationName,
    userName,
    fullName,
    staffId,
    staffCampus,
    onLogoutClick,
    userType,
    appointmentId,
}) => {
    return (
        <div>
            {open && <div className="fixed z-10 inset-0" onClick={close}></div>}
            <div
                className={`${
                    open
                        ? `visible absolute shadow-lg z-[1000] rounded w-[373px] min-w-max bg-white right-[0px] top-[50px] cursor-pointer`
                        : 'hidden'
                }`}
            >
                <div className="pt-[3.6rem] pb-[1.2rem]">
                    <AccountInformation
                        userName={userName}
                        fullName={fullName}
                        staffId={staffId}
                        organizationName={organizationName}
                    />
                    <ProfileLinks userType={userType} appointmentId={appointmentId} />
                    <CampusLinks
                        organizationLogo={organizationLogo}
                        organizationName={organizationName}
                        staffCampus={staffCampus}
                    />
                    <FlexCentredRow onClick={onLogoutClick} className="gap-[10px] px-[2.4rem]">
                        <LogoutIcon />
                        <Text size="1.6rem" weight="400" color="#EF4444">
                            Logout
                        </Text>
                    </FlexCentredRow>
                </div>
            </div>
        </div>
    );
};

export default SwitchCampus;
