import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../components/shared';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { getStudents } from '../../../redux/students/actions';
import { storeUnit } from '../../../utils/originUnit';

const BursaryStudents = ({ unitName }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.student.isLoading);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);
    const { students } = useSelector((store) => store.student);

    const acceptedStudents = students?.filter((student) => student?.status === 'Accepted');
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const query = useMemo(() => {
        let queryParams = {};
        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

   

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <Link
                    to={`/bursary/students/payment-history/${row.original?._id}`}
                    state={{
                        academicStruct: row.original?.academicStructure,
                        studentDet: row.original,
                        isLoading: isLoading,
                    }}
                    style={{ color: '#2563EB' }}
                >
                    {row.original?.lastName} {row.original?.firstName} {row.original?.middleName || ''}
                </Link>
            ),
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Matric Number',
            accessorKey: 'matricNumber',
        },

        {
            header: 'Level',
            accessorKey: 'level',
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
        },

        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
    ];

    const mapStudent = useMemo(
        () =>
            acceptedStudents?.map((item) => {
                const firstLevel = item?.academicStructure?.split('-')[0];
                const secondLevel = item?.academicStructure?.split('-')[1];

                const firstDegree = item[firstLevel];
                const secondDegree = item[secondLevel];

                return { ...item, secondDegree, firstDegree };
            }),
        [acceptedStudents],
    );

    useEffect(() => {
        storeUnit(unitName);
    }, [dispatch, unitName]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStudents(query));
    }, [dispatch, query]);

    return (
        <>
            <PageLayout
                pageTitle="Students"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={mapStudent || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Records "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
            />
        </>
    );
};

export default BursaryStudents;
