import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit3.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import AddNewFacultyForm from '../../../components/forms/add-new-faculty';
import EditFacultyForm from '../../../components/forms/edit-faculty';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteCollege, getColleges } from '../../../redux/college/actions';
import { deleteFaculty, getFaculties } from '../../../redux/faculty/actions';
import { SAVE_CURRENT_FACULTY } from '../../../redux/faculty/slice';
import { getDegreeStructs } from '../../../utils';
import { storeUnit } from '../../../utils/originUnit';

const CONFIRM_TEXT = 'delete';

const Faculties = ({ unitName }) => {
    const dispatch = useDispatch();

    const { currentStructure } = useSelector((state) => state.academicStructure);
    const { secondDegreeOptionsStr = '' } = useSelector((state) => state.academicStructure);
    const { isLoading: facultyLoading, faculties } = useSelector((state) => state.faculty);
    const { isLoading } = useSelector((state) => state.college);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showForm, setShowForm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerms = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    const queryCollege = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchParam = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    const fields = [
        {
            header: 'Name',
            accessorKey: 'facultyName',
            cell: (props) => (
                <Link style={{ color: '#2563EB' }} to={`/faculties/departments`}>
                    {props.getValue()}
                    {process.env.REACT_APP_ENV === 'development' && ` (${props.row.original?.academicStructure})`}
                </Link>
            ),
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'departments',
            cell: ({ row }) => {
                const { secondDegreeStruct } = getDegreeStructs(row.original.academicStructure);
                const totalSeconddegrees =
                    secondDegreeStruct === 'program'
                        ? row.original?.subProgram?.length
                        : row.original?.[secondDegreeStruct]?.length;

                return <p>{totalSeconddegrees || 0}</p>;
            },
        },
        {
            header: 'Personnel',
            accessorKey: 'personnel',
            cell: (props) => <p>{props?.getValue()?.length}</p>,
        },
        {
            header: 'Admin',
            accessorKey: 'facultyAdmin',
            cell: (props) => <p>{props?.getValue()?.length}</p>,
        },
        {
            header: 'Courses',
            accessorKey: 'courses',
            cell: (props) => <p>{props?.getValue()?.length}</p>,
        },
        {
            header: 'Students',
            accessorKey: 'students',
            cell: (props) => <p>{props?.getValue()?.length}</p>,
        },
        {
            header: 'Description',
            accessorKey: 'description',
        },
    ];

    const handleDelete = async () => {
        if (confirmInputValue.toLowerCase() !== CONFIRM_TEXT.toLowerCase()) return toast.error(`Input ${CONFIRM_TEXT}`);

        let res;
        const currStructName = currentStructure?.name?.toLowerCase() || '';
        if (currStructName === 'college') {
            res = await dispatch(deleteCollege(selectedItem?._id));
        }
        if (currStructName === 'faculty') {
            res = await dispatch(deleteFaculty(selectedItem?._id));
        }
        if (res) {
            setShowDelete(false);
            setConfirmInputValue('');
            setSelectedItem(null);
        }
    };

    useEffect(() => {
        const currStructName = currentStructure?.name?.toLowerCase() || '';
        if (currStructName === 'college') {
            dispatch(getColleges(queryCollege));
        }
        if (currStructName === 'faculty') {
            dispatch(getFaculties(query));
        }
    }, [dispatch, currentStructure, query, queryCollege]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <div>
            <PageLayout
                backTitle="Academics"
                backLink="/academics"
                backSubtitle={currentStructure?.name}
                pageTitle={currentStructure?.name}
                secondaryButtonText={`Create ${currentStructure?.name}`}
                onSecondaryButtonClick={() => setShowForm(true)}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onActionBtnClick={() => setDropdownOpen(!dropdownOpen)}
                actionOpen={dropdownOpen}
                actionClose={() => setDropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Edit />,
                        name: `Edit ${currentStructure?.name}`,
                        click: () => {
                            setShowEdit(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedItem,
                    },
                    {
                        icon: <Delete />,
                        name: `Delete ${currentStructure?.name}`,
                        click: () => {
                            setShowDelete(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedItem,
                    },
                ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                searchable
                showTableUtils
                fields={fields}
                data={faculties}
                loading={currentStructure?.name?.toLowerCase() === 'college' ? isLoading : facultyLoading}
                onRowClick={(item) => {
                    const selectedItem = faculties?.find((faculty) => faculty?._id === item?._id);
                    dispatch(SAVE_CURRENT_FACULTY(selectedItem));
                }}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title={`No ${currentStructure?.name}`}
                            subtitle={`Create New ${currentStructure?.name}`}
                        >
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#10B981" color="#fff" onClick={() => setShowForm(true)}>
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <AddNewFacultyForm show={showForm} setShow={setShowForm} />

            {showEdit && (
                <EditFacultyForm
                    show={showEdit}
                    setShow={setShowEdit}
                    currentFaculty={selectedItem}
                    onFinish={() => setSelectedItem(null)}
                />
            )}

            <ConfirmActionDialogue
                show={showDelete}
                close={() => setShowDelete(false)}
                title={`Delete ${currentStructure?.name}?`}
                subtitle={`${currentStructure?.name} of ${selectedItem?.facultyName} will be deleted.`}
                borderLine
                bodyText={`To confirm, enter '${CONFIRM_TEXT}' in the text field.`}
                input
                placeholder={`Input ${CONFIRM_TEXT}`}
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                btn2Text="Yes, Delete"
                confirmAction={handleDelete}
                isLoading={isLoading || facultyLoading}
            />
        </div>
    );
};

export default Faculties;
