import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { AllFilter } from '../../../components/filter-select';
import FilterPopup from '../../../components/filter-select/filter-popup';
import ListEmptyContent from '../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../components/shared';
import { CenteredContainer, RelativeContainer } from '../../../containers/ScreenContainers';
import { LEVELS } from '../../../data/constants';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { getActiveOrganisationUnits } from '../../../redux/organisation-unit/actions';
import { getStudents } from '../../../redux/students/actions';
import { storeUnit } from '../../../utils/originUnit';

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const STATUS_FILTER_OPTIONS = ['Accepted', 'Unaccepted'];

const Student = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const altUnitName = sessionStorage.getItem('unitName');

    const isLoading = useSelector((state) => state.student.isLoading);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const { students = [] } = useSelector((store) => store.student);

    const { sessions } = useSelector((state) => state.admission);

    const acceptedStudents = students?.filter((student) => student?.status === 'Accepted');

    const mapStudent = acceptedStudents?.map((stud) => {
        const firstLevel = stud?.academicStructure?.split('-')?.[0];
        const secondLevel = stud?.academicStructure?.split('-')?.[1];

        const firstDegree = stud[firstLevel];
        const secondDegree = stud[secondLevel];

        return { ...stud, secondDegree, firstDegree };
    });

    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [firstNameFilter, setFirstNameFilter] = useState('');
    const [middleNameFilter, setMiddleNameFilter] = useState('');
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [sessionFilter, setSessionFilter] = useState('');
    const [dateCreatedFilter, setDateCreatedFilter] = useState('');
    const [firstDegreeFilter, setFirstDegreeFilter] = useState('');
    const [secondDegreeFilter, setSecondDegreeFilter] = useState('');
    const [levelFilter, setLevelFilter] = useState('');
    const [matricNumberFilter, setMatricNumberFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const firstNameList = mapStudent?.map((item) => item?.firstName);
    const middleNameList = mapStudent?.map((item) => item?.middleName);
    const lastNameList = mapStudent?.map((item) => item?.lastName);
    const secondDegreeList = mapStudent?.map((item) => item?.secondDegree);
    const firstDegreeList = mapStudent?.map((item) => item?.firstDegree);
    const matricNumberList = mapStudent?.map((item) => item?.matricNumber);
    const sessionList = sessions?.map((item) => item?.admissionYear);
    const DateCreatedList = mapStudent?.map((item) => item?.createdAt);

    const generateFilterOptions = (data = [], setStateAction, closeAction, objKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setStatusFilter('');
        setSearchValue('');
        setFirstNameFilter('');
        setLastNameFilter('');
        setMiddleNameFilter('');
        setFirstDegreeFilter('');
        setSecondDegreeFilter('');
        setMatricNumberFilter('');
        setLevelFilter('');
        setDateCreatedFilter('');
        setSessionFilter('');
    };

    const FILTER_POPUP_OPTIONS = {
        'First Name': generateFilterOptions(firstNameList, setFirstNameFilter, setOpenModal),
        'Other Name': generateFilterOptions(middleNameList, setMiddleNameFilter, setOpenModal),
        Surname: generateFilterOptions(lastNameList, setLastNameFilter, setOpenModal),
        Session: generateFilterOptions(sessionList, setSessionFilter, setOpenModal),
        Status: generateFilterOptions(STATUS_FILTER_OPTIONS, setStatusFilter, setOpenModal),
        'First Degree': generateFilterOptions(firstDegreeList, setFirstDegreeFilter, setOpenModal),
        'Second Degree': generateFilterOptions(secondDegreeList, setSecondDegreeFilter, setOpenModal),
        Level: generateFilterOptions(LEVELS, setLevelFilter, setOpenModal),
        'Matric Number': generateFilterOptions(matricNumberList, setMatricNumberFilter, setOpenModal),
        'Date Created': generateFilterOptions(DateCreatedList, setDateCreatedFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        'First Name': {
            value: firstNameFilter,
            stateAction: setFirstNameFilter,
        },
        'Other Name': {
            value: middleNameFilter,
            stateAction: setMiddleNameFilter,
        },
        Surname: {
            value: lastNameFilter,
            stateAction: setLastNameFilter,
        },
        'First Degree': {
            value: firstDegreeFilter,
            stateAction: setFirstDegreeFilter,
        },
        'Second Degree': {
            value: secondDegreeFilter,
            stateAction: setSecondDegreeFilter,
        },

        Level: {
            value: levelFilter,
            stateAction: setLevelFilter,
        },
        Session: {
            value: sessionFilter,
            stateAction: setSessionFilter,
        },
        'Matric Number': {
            value: matricNumberFilter,
            stateAction: setMatricNumberFilter,
        },
        Status: {
            value: statusFilter,
            stateAction: setStatusFilter,
        },
        'Date Created': {
            value: dateCreatedFilter,
            stateAction: setDateCreatedFilter,
        },
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const FILTER_OPTIONS = useMemo(() => {
        return [
            'Level',
            'First Name',
            'Other Name',
            'Surname',
            'First Degree',
            'Second Degree',
            'Matric Number',
            'Session',
            'Status',
            'Date Created',
        ];
    }, []);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }

        if (firstNameFilter) {
            queryParams.firstName = firstNameFilter;
        }
        if (middleNameFilter) {
            queryParams.middleName = middleNameFilter;
        }
        if (lastNameFilter) {
            queryParams.lastName = lastNameFilter;
        }
        if (firstDegreeFilter) {
            queryParams.college = firstDegreeFilter;
        }
        if (secondDegreeFilter) {
            queryParams.program = secondDegreeFilter;
        }
        if (levelFilter) {
            queryParams.level = levelFilter;
        }
        if (sessionFilter) {
            queryParams.currentSession = sessionFilter;
        }
        if (matricNumberFilter) {
            queryParams.matricNumber = matricNumberFilter;
        }

        if (statusFilter) {
            queryParams.status = statusFilter;
        }
        if (dateCreatedFilter) {
            queryParams.createdAt = dateCreatedFilter;
        }

        return queryParams;
    }, [
        firstDegreeFilter,
        firstNameFilter,
        middleNameFilter,
        lastNameFilter,
        sessionFilter,
        levelFilter,
        secondDegreeFilter,
        matricNumberFilter,
        statusFilter,
        dateCreatedFilter,
        debouncedSearchValue,
    ]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        storeUnit(unitName);
        dispatch(getSavedAcademicStructure());
        dispatch(getActiveOrganisationUnits());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStudents(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, query]);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <p style={{ color: '#2563EB' }} className="cursor-pointer">{`${row.original.lastName} ${
                    row.original.firstName
                } ${row.original.middleName || ''}`}</p>
            ),
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
        },
        {
            header: 'Level',
            accessorKey: 'level',
        },
        {
            header: 'Created Date',
            accessorKey: 'admissionYear',
        },
        {
            header: 'Session',
            accessorKey: 'admissionSession',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
    ];

    return (
        <>
            <PageLayout
                pageTitle="Students"
                searchable
                fields={fields}
                data={mapStudent}
                showTableUtils
                showFilter
                filterItems={FILTER_OPTIONS.map((item) => ({
                    name: item,
                    click: () => {
                        handleSelectCategories(item);
                        setCurrentFilterCategory(item);
                        setOpenModal('options-popup-main');
                    },
                }))}
                showClear={currentFilterCategory}
                handleFilterReset={handleReset}
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
                filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Records"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isFilterPopLoading={isLoading}
                onRowClick={(item) => {
                    const selectedItem = mapStudent?.find((stu) => stu?._id === item?._id);
                    navigate(`${item?._id}`, {
                        state: {
                            academicStruct: item?.academicStructure,
                            studentDet: selectedItem,
                            currentUnit: altUnitName,
                        },
                    });
                }}
                filterCategories={
                    <div>
                        {filterCategories?.length > 0 && (
                            <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                                {filterCategories.map((category) => (
                                    <div key={category} className="flex gap-[1px]">
                                        <StyledTag withLeftRadius>{category}</StyledTag>

                                        {FILTER_VALUES_LOOKUP[category].value && (
                                            <StyledTag withRightRadius>
                                                {FILTER_VALUES_LOOKUP[category].value}
                                                <CloseIcon
                                                    className="ml-[4px] hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveFilterCategory(
                                                            category,
                                                            FILTER_VALUES_LOOKUP[category].stateAction,
                                                        )
                                                    }
                                                />
                                            </StyledTag>
                                        )}
                                    </div>
                                ))}

                                <RelativeContainer>
                                    <AllFilter
                                        useSecondaryBtn
                                        showClear={currentFilterCategory}
                                        items={FILTER_OPTIONS.map((item) => ({
                                            name: item,
                                            click: () => {
                                                handleSelectCategories(item);
                                                setCurrentFilterCategory(item);
                                                setOpenModal('options-popup-sub');
                                            },
                                        }))}
                                        handleReset={handleReset}
                                    />
                                    <FilterPopup
                                        open={openModal === 'options-popup-sub'}
                                        isLoading={isLoading}
                                        close={() => setOpenModal(null)}
                                        items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                    />
                                </RelativeContainer>
                            </div>
                        )}
                    </div>
                }
            />
        </>
    );
};

export default Student;
